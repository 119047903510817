import React from "react"
import {
  DiscussBox,
  QuoteBox,
  ToSeeBox,
  ActivityBox,
  CopyrightBox,
  DefinitionBox
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import evacueesFrame from './evacuees.jpg'
import { Video } from '../../../components/video'
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const WW2 = () => (
  <Layout title="World War Two">
    <SmallSectionHeader
      color={theme.colors.purple.dark}
      image={headerImage}
      to="/a-walk-through-time"
    >
      A walk through time
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/a-walk-through-time', name: 'A walk through time' },
      { name: 'World War Two' }
    ]} />
    <Page>
      <h1>World War Two</h1>
      <p className="page-left">On 1 June 1942 Canterbury was severely bombed in what became known as the ‘Baedeker Raids’. The Cathedral was a key target of the raid. When the bombing began four men who had been designated as ‘fire watchers’ rushed to the roof of the Cathedral. Their job was spot and put out incendiary bombs before they could cause a fire. Thanks to their bravery the Cathedral survived the raid largely unscathed despite sixteen bombs falling nearby and the damage to the Deanery and Library.</p>
      <DefinitionBox title="Incendiary Bombs" className="page-right">Bombs designed to start fires.</DefinitionBox>
      <DiscussBox>
        How would it feel to see the Cathedral still standing in the morning?
      </DiscussBox>

      <h2>Baedeker Raids</h2>
      <p className="page-left">On the 28 March 1942 the British Royal Air Force had attacked the German port city of Lübeck causing huge damage. In response Hitler planned a bombing campaign which focused on cultural sites in cities like Canterbury, Exeter and York. Two days before Canterbury was bombed much of the medieval city of Cologne was destroyed by British bombers. The attack on Canterbury was reported in the German media as revenge for Cologne. The bombing campaign was called the ‘Baedeker Raids’ because the Baedeker guide-book – a popular German tourist guide – was allegedly used to decide which English cities to bomb.</p>
      <Figure
        className="page-right"
      >
        <StaticImage
          src="./baedecker.jpg"
          alt="Canterbury during the war"
        />
      </Figure>

      <QuoteBox cite="Tom Hoare, lead fire watcher">
        We could hear the roar of the planes overhead and when we looked up we saw flares shoot up into the sky from them and explosives and incendiaries began to fall. They seemed to be coming down like hailstones.
      </QuoteBox>
      <DiscussBox>
        What would it have been like to be living in Canterbury on the night of the Baedeker Raid?
      </DiscussBox>
      <DiscussBox>
        What was the significance of the Baedecker raids on World War Two?
      </DiscussBox>

      <p className="page-left">43 people lost their lives and 100 were wounded in the attack on Canterbury. 800 buildings in the city were destroyed. After the raid many people from Canterbury gathered at the Cathedral to demonstrate solidarity against this attack on British morale.</p>

      <DefinitionBox title="Evacuee" className="page-right">
        During the war, bombs were falling on many cities and built up areas. A lot of children were evacuated or sent away from cities across Britain to the countryside which was thought to be safer. These children were called evacuees.
      </DefinitionBox>

      <Figure
        caption="This film clip shows two children returning home to Canterbury having been evacuated. They are shown visiting familiar places including the Cathedral. (C) British Pathe"
      >
        <Video placeholder={evacueesFrame}
          sources={[
            { type: "mp4", src: "evacuees.mp4" },
            { type: "webm", src: "evacuees.webm" }
          ]}
        />
      </Figure>

      <ToSeeBox
        items={[
          {
            location: "Nave",
            items: [
              {
                title: "The memorial to the Baedecker raids fire watchers",
                content:
                  "This memorial was installed in the Cathedral in 2004 following a campaign by the local newspaper. It is one of several memorials to the events of 1942 in the city",
              },
            ],
          },
          {
            location: "South East Transept",
            items: [
              {
                title: "Bossanyi Windows",
                content:
                  "The windows in this part of the Cathedral were destroyed in World War Two. New windows were designed by the Hungarian artist Edwin Bossanyi and installed in 1960. Bossanyi had fled Hungary after Hitler’s rise to power. The larger, lower windows explore the themes of Salvation and Peace.",
              },
            ],
          },
          {
            location: "All around the Cathedral",
            items: [
              {
                title: "Stained glass fragments",
                content:
                  "At the beginning of the war the medieval stained glass windows were taken out and stored in boxes in the Corona towers and St Gabriel’s Chapel until the war was over. However, many of the later Victorian windows were not removed and were destroyed in the bombings.",
              },
            ],
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="A Walk Through Time activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/AWalkThroughTime.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘A walk through time’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "Saint Augustine", to: "/a-walk-through-time/st-augustine" },
          { name: "The Vikings", to: "/a-walk-through-time/vikings" },
          { name: "Archbishop Lanfranc", to: "/a-walk-through-time/lanfranc" },
        ]}
      />

      <hr />

      <CopyrightBox />
    </Page>
  </Layout>
)

export default WW2